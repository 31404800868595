<template>
  <div class="uk-card uk-card-default uk-text-center uk-border-rounded uk-card-hover interactive uk-padding-small-bottom uk-height-small" :class="{ 'selected': selectedFile === interactive.id }" @click="$emit( 'click' )">
    <div class="uk-margin-small-top">
        <span class="bi bi-film"  style="font-size: 3rem;" ></span>
    </div>
    <p class="uk-text-truncate uk-dark uk-margin-small uk-margin-small-left uk-margin-small-right uk-card-title title">{{ interactive.name }}<br />
      <span class="uk-text-light uk-text-small uk-margin-remove-bottom">{{ interactive.scenes.length }} Scenes</span><br />
      <span class="uk-text-light uk-text-small uk-margin-remove-top">{{ interactive.createdAt | date }}</span><br />
    </p>
  </div>
</template>
<script>

export default {
  name: 'InteractiveCard',
  props: {
    interactive: { type: Object, required: true },
    selectedFile: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.interactive {
  cursor: pointer;
}

.selected {
  border: 1px solid var(--app-primary-color);
}

.title {
  font-size: 14px;
}
</style>
