<template>
  <div>
    <div class="uk-text-left">
      <h2 class="uk-margin-remove"> {{orgName}} </h2>
    </div>
    <h3 class="uk-text-left uk-margin-small-top">{{title ||  `${$t('Actions.Select')} ${$t('enums.ContentType.INTERACTIVE')}`}}</h3>
    <hr />
    <span class="fixed" v-if="fetching" uk-spinner="ratio: 2"> </span>
    <div 
      class="uk-grid-small uk-width-1-1 uk-grid-match uk-padding-small fixed"
      uk-grid
      id="modal-list-interactive-items"
      uk-scrollspy="target: > div; cls: uk-animation-scale-up; offset-top: 100;"
      v-if="!fetching"
      uk-overflow-auto
      @scroll="infiniteScroll()"
    >
      <div 
          v-for="(iv, i) in ivs"
          :key="i"
          class="uk-grid-item-match uk-width-1-6@l uk-width-1-4@m uk-width-1-2@s"
        >
        <InteractiveCard :interactive="iv.node" :selected-file="selectedFile.id" @click="selectedFile = iv.node"/>
      </div>
    </div>
    <div v-if="loadMore" class="uk-margin-small-top" uk-spinner="ratio: 1"></div>
    <hr>
      <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancel"> {{ $t('Actions.Cancel') }} </button>
      <button v-if="!submit" :disabled="!selectedFile" class="uk-button uk-button-primary uk-margin-small-right" @click="select" type="button"> {{ buttonText }} </button>
      <button v-else class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="true"  > <span uk-spinner="ratio: 1"> </span> </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import InteractiveCard from '../cards/InteractiveCard';

export default {
  name: 'ModalInteractivePicker',
  components: { InteractiveCard },
  props: {
    title: { type: String, default: '' },
    submit: { type: Boolean, default: false },
    buttonText: { type: String, default: 'Select' }
  },
  data () {
    return {
      fetching: false,
      selectedFile: '',
      loadMore: false,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      ivs: state => (state.venom.org.interactives) ? state.venom.org.interactives.edges : [],
      orgName: state => state.venom.org.name,
      pageInfo: state => (state.venom.org.interactives) ? state.venom.org.interactives.pageInfo : {}
    }),
  },
  mounted() {
    this.fetchOrgInteractives( false );
  },
  methods: {
    async fetchOrgInteractives( isNextPage ) {
        isNextPage ? this.loadMore = true : this.fetching = true;
        await this.$store.dispatch('fetchInteractives', {
        orgId: this.orgId,
        first: 25,
        after: isNextPage ? ( this.pageInfo.hasNextPage ? this.pageInfo?.endCursor : '' ) : '',
        isNextPage: isNextPage
      });   
        isNextPage ? this.loadMore = false : this.fetching = false;
    },
    infiniteScroll() {
      const { scrollTop, scrollHeight, offsetHeight } = document.getElementById('modal-list-interactive-items');
        let bottomOfWindow = scrollTop + offsetHeight === scrollHeight;
        if( bottomOfWindow && !this.fetching && this.pageInfo.hasNextPage ){
          this.fetchOrgInteractives( true );
      }
    },
    select () {
      this.$emit('selectInteractive', this.selectedFile );
      this.selectedFile = '';
    },
    cancel () {
      this.$emit('cancel');
      this.selectedFile = '';
      this.fetchOrgInteractives( false );
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  height: 55vh;
}
</style>